import styles from './styles.module.css';
import { BLOCKS } from '@contentful/rich-text-types';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';

import { ContentThumbnailCard } from 'src/components/contentThumbnailCard';
import { SectionContainer, SectionContainerGrid } from 'src/components/sectionContainer';
import { ContentfulProjectResource, Maybe } from 'src/graphql-types';
import * as Icons from 'src/icons';
import { DefaultLayoutTemplate } from 'src/pages/layout';
import {
	defaultRichTextOptions,
	AnchorTitle,
	AnchorTitleTypes,
	getRichText,
} from 'src/templates/utils/richTextOptions/defaultOptions';
import {
	ContentfulTypes,
	SERVER_RESPONSE_NO_ACCESS,
	BackEndContentTypes,
	SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE,
	AccessibleByValues,
	formatDate,
} from 'src/utils/common';
import { LinkHelper } from 'src/utils/link-helper';
import { ApolloError } from '@apollo/client';
import { LoginForMore } from 'src/components/loginComponents/loginForMore';
import { curatedTitle } from 'src/utils/curated-title';

interface PageContext {
	pageContext: {
		project: Maybe<ContentfulProjectResource>;
		loading?: boolean;
		error?: ApolloError;
		refetch?: (params?: any) => any;
	};
}

interface MessageProps {
	message: string;
}

const SimpleMessageContent = (props: MessageProps) => {
	return (
		<SectionContainer withBorderTop>
			<p className={styles.message}>{props.message}</p>
		</SectionContainer>
	);
};

export default class ProjectDetailsTemplate extends React.Component<PageContext> {
	contentsOptions = {
		...defaultRichTextOptions,
		renderNode: {
			...defaultRichTextOptions.renderNode,
			[BLOCKS.HEADING_6]: (node: any, children: any) => {
				return (
					<AnchorTitle
						children={children}
						titleType={AnchorTitleTypes.H6}
						className={styles.contentsTitle}
					/>
				);
			},
			[BLOCKS.PARAGRAPH]: (node: any, children: any) => {
				return <p className={styles.contentsParagraph}>{children}</p>;
			},
		},
	};

	render() {
		const { project, loading = false, error, refetch } = this.props.pageContext;

		const notLoggedIn = error && error.message === SERVER_RESPONSE_NO_ACCESS;
		const notAuthorised = error && error.message === SERVER_RESPONSE_NO_ACCESS_TO_USER_TYPE;

		const getContent = () => {
			if (loading) {
				return <SimpleMessageContent message="Loading content, please wait" />;
			}
			if (error) {
				if (notLoggedIn) {
					return <LoginForMore onRefetch={refetch} withSectionContainer />;
				} else if (notAuthorised) {
					return <SimpleMessageContent message="this content is not available" />;
				} else {
					return (
						<SimpleMessageContent message="There was a problem getting the resource, please try again later." />
					);
				}
			}
			if (project) {
				const {
					associatedResources,
					projectType,
					title,
					contents,
					projectCode,
					researchOrganisations,
					species,
					startDate,
					status,
					projectEndDate,
					relatedProjects,
				} = project;
				const associatedResourcesGrid = associatedResources ? (
					<SectionContainerGrid columns="2">
						{associatedResources
							.filter((resource: any) => resource)
							.map((resource: any, i: number) => {
								let linkTo: string | undefined = LinkHelper.linkTo(resource);
								let videoLinkTo: string | undefined = undefined;
								let externalLink: string | undefined = undefined;
								let downloadLink: string | undefined = undefined;
								const resourceType: ContentfulTypes | BackEndContentTypes = resource.internal?.type;
								const Icon =
									Icons[resource.thumbnailLink?.thumbnailIcon as keyof typeof Icons] || undefined;
								const isPublic =
									!resource?.accessibleBy ||
									(resource?.accessibleBy?.length &&
										resource?.accessibleBy.find(
											(ab: AccessibleByValues) => ab === AccessibleByValues.Public
										));
								switch (resourceType) {
									case ContentfulTypes.Article:
									case BackEndContentTypes.Article:
										if (resource.externalArticleUrl) {
											linkTo = undefined;
											externalLink = resource.externalArticleUrl;
										}
										break;
									case ContentfulTypes.Publication:
									case BackEndContentTypes.Publication:
										if (resource.externalPublicationUrl) {
											linkTo = undefined;
											externalLink = resource.externalPublicationUrl;
										}
										downloadLink = isPublic ? resource.publicationFile?.file?.url : undefined;
										break;
									case ContentfulTypes.Report:
									case BackEndContentTypes.Report:
										downloadLink = isPublic ? resource.reportFile?.file?.url : undefined;
										break;
									case ContentfulTypes.Video:
									case BackEndContentTypes.Video:
										videoLinkTo = resource.videoFile?.file?.url || resource.externalVideoUrl;
										downloadLink = isPublic ? resource.videoFile?.file?.url : undefined;
										break;
								}

								return (
									<ContentThumbnailCard
										key={resource.contentful_id}
										imageSrc={resource.thumbnailLink?.thumbnailImage?.fluid?.src}
										Icon={Icon}
										linkTo={linkTo}
										videoLinkTo={videoLinkTo}
										externalLink={externalLink}
										downloadLink={downloadLink}
										asVideo={
											resourceType === ContentfulTypes.Video ||
											resourceType === BackEndContentTypes.Video
										}
									>
										{resource.thumbnailLink?.title}
									</ContentThumbnailCard>
								);
							})}
					</SectionContainerGrid>
				) : undefined;

				return (
					<SectionContainer
						isGrey
						withBorderTop
						style={{ display: 'flex', justifyContent: 'center' }}
					>
						<div>
							<div className={styles.titleContainer}>
								<h6 className={styles.projectTypeTitle}>{projectType}</h6>
								<h4 className={styles.titleText}>{title}</h4>
							</div>
							<div className={styles.projectSection}>
								<div className={styles.detailsContainer}>
									{getRichText(contents, this.contentsOptions)}
									{associatedResourcesGrid && (
										<div>
											<h6 className={styles.contentsTitle}>Associated Resources</h6>
											<div className={styles.associatedArticlesAndPublicationsGridContainer}>
												{associatedResourcesGrid}
											</div>
										</div>
									)}
								</div>
								<div className={styles.metadataContainer}>
									{projectCode && (
										<div>
											<h6 className={styles.metadataTitle}>Project Code</h6>
											<p className={styles.metadataParagraph}>{projectCode}</p>
										</div>
									)}
									{researchOrganisations?.length && (
										<div>
											<h6 className={styles.metadataTitle}>
												Research Organisation{researchOrganisations.length > 1 ? 's' : ''}
											</h6>
											<p className={styles.metadataParagraph}>
												{researchOrganisations
													?.filter((r) => r)
													.map((r, i) => {
														return (
															<span className={styles.researchOrganisation} key={r || i}>
																{r}
															</span>
														);
													})}
											</p>
										</div>
									)}
									{(species?.length || 0) > 0 && (
										<div>
											<h6 className={styles.metadataTitle}>Species</h6>
											<p className={styles.metadataParagraph}>
												{species
													?.filter((s) => s)
													.map((s, i) => {
														const separator = i > 0 ? ',' : '';
														return <span key={s || i}>{`${separator} ${s}`}</span>;
													})}
											</p>
										</div>
									)}
									{startDate && (
										<div>
											<h6 className={styles.metadataTitle}>Start Date</h6>
											<p className={styles.metadataParagraph}>{formatDate(startDate)}</p>
										</div>
									)}
									{status && (
										<div>
											<h6 className={styles.metadataTitle}>Status</h6>
											<p className={styles.metadataParagraph}>{status}</p>
										</div>
									)}
									{projectEndDate && (
										<div>
											<h6 className={styles.metadataTitle}>Project End Date</h6>
											<p className={styles.metadataParagraph}>{formatDate(projectEndDate)}</p>
										</div>
									)}
									{(relatedProjects?.length || 0) > 0 && (
										<div>
											<h6 className={styles.metadataTitle}>Related Projects</h6>
											<p className={classNames(styles.metadataParagraph, styles.relatedProjects)}>
												{relatedProjects
													?.filter((rp) => rp)
													.map((rp) => {
														return (
															<AnchorLink
																className={styles.metadataHyperlink}
																key={rp?.contentful_id || ''}
																to={LinkHelper.getLinkOfContentType(
																	ContentfulTypes.Project,
																	rp?.contentful_id
																)}
															>{`${rp?.projectCode} - ${rp?.title}`}</AnchorLink>
														);
													})}
											</p>
										</div>
									)}
								</div>
							</div>
						</div>
					</SectionContainer>
				);
			}

			return (
				<SimpleMessageContent message="There was a problem getting the resource, please try again later." />
			);
		};

		return (
			<DefaultLayoutTemplate showLogin={notLoggedIn} onLoginSuccess={refetch}>
				<Helmet>
					<title>{curatedTitle(this.props.pageContext.project?.title)}</title>
				</Helmet>
				{getContent()}
			</DefaultLayoutTemplate>
		);
	}
}
