import styles from './styles.module.css';
import classNames from 'classnames';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React, { CSSProperties, ReactNode, FunctionComponent } from 'react';
import { DownloadIcon } from 'src/icons/download';
import { Video } from 'src/components/video';

export interface ContentThumbnailCardProps {
	imageSrc?: string | null;
	Icon?: FunctionComponent<any>;
	tripleBorder?: 'left' | 'right';
	linkTo?: string | null;
	videoLinkTo?: string | null;
	externalLink?: string | null;
	style?: CSSProperties;
	className?: string;
	innerWrapperClassName?: string;
	children?: ReactNode;
	downloadLink?: string | null;
	asVideo?: boolean;
}

export const ContentThumbnailCard = (props: ContentThumbnailCardProps) => {
	const {
		imageSrc,
		Icon,
		linkTo,
		videoLinkTo,
		externalLink,
		style,
		children,
		downloadLink,
		tripleBorder,
		className,
		innerWrapperClassName,
		asVideo,
	} = props;

	const downloadJsx = downloadLink ? (
		<a className={styles.downloadContainer} href={downloadLink} download target="_blank">
			<DownloadIcon style={{ paddingLeft: '0' }} /> DOWNLOAD
		</a>
	) : undefined;

	const borderLeftJsx =
		tripleBorder === 'left' ? (
			<>
				<div className={styles.borderLeft} />
				<div className={classNames([styles.borderLeft, styles.borderLeftMiddleDiv])} />
			</>
		) : undefined;

	const borderRightJsx =
		tripleBorder === 'right' ? (
			<>
				<div className={styles.borderRight} />
				<div className={classNames([styles.borderRight, styles.borderRightMiddleDiv])} />
			</>
		) : undefined;

	const innerWrapperClass = classNames([
		styles.innerWrapper,
		{ [styles.innerWrapperWithBorderLeft]: tripleBorder === 'left' },
		{ [styles.innerWrapperWithBorderRight]: tripleBorder === 'right' },
		innerWrapperClassName,
	]);

	const contentContainerClass = classNames([
		styles.contentContainer,
		{ [styles.contentContainerBorderLeft]: tripleBorder === 'left' },
		{ [styles.contentContainerBorderRight]: tripleBorder === 'right' },
	]);

	const wrapperClass = classNames(styles.wrapper, className);

	const getLinkJsx = () => {
		if (externalLink) {
			return (
				<div className={contentContainerClass}>
					<p>
						<a className={styles.content} href={externalLink} target="_blank">
							{children}
						</a>
					</p>
					{downloadJsx && <p>{downloadJsx}</p>}
				</div>
			);
		}
		if (linkTo) {
			return (
				<div className={contentContainerClass}>
					<p>
						<AnchorLink className={styles.content} to={linkTo}>
							{children}
						</AnchorLink>
					</p>
					{downloadJsx && <p>{downloadJsx}</p>}
				</div>
			);
		}
		return (
			<div className={contentContainerClass}>
				<div className={styles.content}>{children}</div>
				{downloadJsx}
			</div>
		);
	};

	if (asVideo) {
		return (
			<div className={wrapperClass} style={style}>
				{borderLeftJsx}
				<div className={innerWrapperClass} style={style}>
					<div className={styles.imageContainer}>
						<Video
							videoUrl={videoLinkTo || externalLink || '/'}
							thumbnailImageSrc={imageSrc}
							linkToDetails={{
								linkTo: linkTo || externalLink || '/',
								targetBlank: Boolean(externalLink),
							}}
						/>
					</div>
					{getLinkJsx()}
				</div>
				{borderRightJsx}
			</div>
		);
	}

	return (
		<div className={wrapperClass} style={style}>
			{borderLeftJsx}
			<div className={innerWrapperClass} style={style}>
				{imageSrc && (
					<div
						className={styles.imageContainer}
						style={{ backgroundImage: `url(${imageSrc})` }}
					></div>
				)}
				{!imageSrc && Icon && <div className={styles.imageContainer}>{Icon && <Icon />}</div>}
				{getLinkJsx()}
			</div>
			{borderRightJsx}
		</div>
	);
};
