import styles from './styles.module.css';
import React, { useState, ReactNode, FunctionComponent } from 'react';
import { CaretIcon } from 'src/icons/caret';
import { LockIcon } from 'src/icons/lock';
import { LoginResult, LoginFormPopup } from 'src/components/loginComponents/loginFormPopup';
import { SectionContainer } from 'src/components/sectionContainer';

interface Props {
	onRefetch?: (params?: any) => any;
	withSectionContainer?: boolean;
}

interface WrapperProps {
	withSectionContainer?: boolean;
}

const Wrapper: FunctionComponent<WrapperProps> = (props) => {
	const { children, withSectionContainer } = props;
	return withSectionContainer ? (
		<SectionContainer withBorderTop>{children}</SectionContainer>
	) : (
		<>{children}</>
	);
};

export const LoginForMore = (props: Props) => {
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const { onRefetch, withSectionContainer } = props;

	const onLogin = (result: LoginResult) => {
		if (result.loggedIn) {
			onRefetch && onRefetch();
			setShowLoginPopup(false);
		}
	};

	return (
		<Wrapper withSectionContainer={withSectionContainer}>
			<div className={styles.wrapper}>
				<div className={styles.iconContainer}>
					<LockIcon color="#52D07A" />
				</div>
				<h5 className={styles.text}>
					If you are a member of the industry with a user account, you can­­ log in to see more
				</h5>
				<div className={styles.action} onClick={() => setShowLoginPopup(true)}>
					LOGIN NOW <CaretIcon direction="right" color="#52D07A" />
				</div>
			</div>
			{showLoginPopup && (
				<LoginFormPopup onLogin={onLogin} onClose={() => setShowLoginPopup(false)} />
			)}
		</Wrapper>
	);
};
